
<template>
  <div>
    <CCard accentColor="primary">
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Username">
                  <CInput
                    label="User Name"
                    placeholder="User Name"
                    v-model="form.username"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Nama Lengkap">
                  <CInput
                    label="Nama Lengkap"
                    placeholder="Nama Lengkap"
                    v-model="form.fullname"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <validation-provider rules="required|email" v-slot="{ errors }" name="Email">
                  <CInput
                    type="email"
                    label="Email"
                    placeholder="Email"
                    v-model="form.email"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <CInput label="NIP" placeholder="NIP" v-model="form.nip" :isValid="true"></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <div role="group" class="form-group">
                  <label class>Role</label>
                  <validation-provider rules="required" v-slot="{ errors }" name="Role">
                    <v-select
                      v-model="form.role_id"
                      :options="roleOption"
                      label="role_name"
                      :reduce="(roles) => roles.id"
                      :filterable="true"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></v-select>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>
            <CRow v-if="form.id == null">
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Password">
                  <CInput
                    type="password"
                    label="Password"
                    placeholder="Password"
                    v-model="form.password"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <validation-provider
                  vid="confirm"
                  rules="required|confirmed:Password"
                  v-slot="{ errors }"
                  name="Konfirmasi Password"
                >
                  <CInput
                    type="password"
                    label="Konfirmasi Password"
                    placeholder="Konfirmasi Password"
                    v-model="form.password_confirmation"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow v-else>
              <CCol md="6">
                <validation-provider v-slot="{ errors }" name="Password">
                  <CInput
                    type="password"
                    label="Password"
                    placeholder="Password"
                    v-model="form.password"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <validation-provider
                  vid="confirm"
                  rules="confirmed:Password"
                  v-slot="{ errors }"
                  name="Konfirmasi Password"
                >
                  <CInput
                    type="password"
                    label="Konfirmasi Password"
                    placeholder="Konfirmasi Password"
                    v-model="form.password_confirmation"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VSelect from "vue-select";
import Swal from "sweetalert2";
export default {
  name: "User",
  components: {
    ValidationProvider,
    ValidationObserver,
    VSelect,
  },
  data() {
    return {
      errorMessage: null,
      form: {
        id: null,
        username: null,
        fullname: null,
        email: null,
        password: null,
        password_confirmation: null,
        nip: null,
        role_id: null,
      },
      loading: false,
      item: null,
      roleOption: [],
    };
  },
  mounted() {
    this.generateRole();
  },
  watch: {
    item: function (item) {
      if (item) {
        this.form.id = item.id;
        this.form.username = item.username;
        this.form.fullname = item.fullname;
        this.form.email = item.email;
        this.form.nip = item.nip;
        this.form.role_id = item.role_id;
      }
      this.generateRole();
    },
  },
  methods: {
    async generateRole() {
      this.roleOption = await this.$store.dispatch("role/autocomplete");
    },
    resetForm() {
      this.form.nama_layanan = null;
    },
    populateInput(item) {
      this.form = {
        id: null,
        username: null,
        fullname: null,
        email: null,
        password: null,
        password_confirmation: null,
        nip: null,
        role_id: null,
      };
      this.item = item;
    },
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = this.item
          ? await this.$store.dispatch("users/update", {
            data: this.form,
            id: vm.item.id,
          })
          : await this.$store.dispatch("users/store", this.form);
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
