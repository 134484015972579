var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accentColor":"primary"}},[_c('CCardBody',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"User Name","placeholder":"User Name","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)})],1),_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nama Lengkap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Nama Lengkap","placeholder":"Nama Lengkap","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.fullname),callback:function ($$v) {_vm.$set(_vm.form, "fullname", $$v)},expression:"form.fullname"}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"type":"email","label":"Email","placeholder":"Email","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"NIP","placeholder":"NIP","isValid":true},model:{value:(_vm.form.nip),callback:function ($$v) {_vm.$set(_vm.form, "nip", $$v)},expression:"form.nip"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("Role")]),_c('validation-provider',{attrs:{"rules":"required","name":"Role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:[
                      { 'is-valid': !errors[0] },
                      { 'is-invalid': errors[0] } ],attrs:{"options":_vm.roleOption,"label":"role_name","reduce":function (roles) { return roles.id; },"filterable":true},model:{value:(_vm.form.role_id),callback:function ($$v) {_vm.$set(_vm.form, "role_id", $$v)},expression:"form.role_id"}})]}}],null,true)})],1)])],1),(_vm.form.id == null)?_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('CInput',{attrs:{"type":"password","label":"Password","placeholder":"Password","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1),_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"confirm","rules":"required|confirmed:Password","name":"Konfirmasi Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('CInput',{attrs:{"type":"password","label":"Konfirmasi Password","placeholder":"Konfirmasi Password","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)})],1)],1):_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('CInput',{attrs:{"type":"password","label":"Password","placeholder":"Password","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1),_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"confirm","rules":"confirmed:Password","name":"Konfirmasi Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('CInput',{attrs:{"type":"password","label":"Konfirmasi Password","placeholder":"Konfirmasi Password","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"6"}},[(_vm.loading)?_c('CSpinner',{attrs:{"color":"success","size":"sm"}}):_c('CButton',{staticClass:"px-4",attrs:{"color":"primary","type":"submit"}},[_vm._v("Simpan")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }